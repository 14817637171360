@import "../vars";

.processes {
  display: flex;
  flex-flow: column;
  border-top: 1px solid var(--color-border-light); }

.processes-content {
  margin-top: 10px;
  margin-bottom: 10px;
  overflow-y: auto; }

.process {
  padding: 12px;
  padding-bottom: 24px;
  position: relative;
  overflow: hidden;
  animation: slideFromLeft $time * 3 $easeMd;
  transition: padding $time $easeMd, background-color $time $easeMd;

  .process-counters {
    overflow: hidden;
    position: absolute;
    left: 0;
    bottom: 12px;
    width: 100%;
    padding: 0 12px; }

  .process-counter {
    border-bottom-width: 2px;
    height: 0; }

  .process-counter-label {
    opacity: 0; }

  &:not(:first-child) {
    margin-top: 12px; }

  &:hover {
    padding-bottom: 36px;

    .process-counter {
      height: 24px;
      border-bottom-width: 4px; }

    .process-counter-label {
      opacity: 1; } } }

.process--active {
  background-color: var(--color-background-gray200); }

@keyframes slideFromLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0); }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

.process-title {
  margin-bottom: 2px;
  font-weight: 500;
  position: relative;
  padding-right: 20px;

  .hint {
    margin-bottom: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: none;
    font-size: $fontSizeSmall; } }

.process-type {
  overflow: hidden;
  text-overflow: ellipsis; }

.process-title-icon {
  position: absolute;
  right: 0;
  top: 0;
  width: 14px;
  height: 14px;
  cursor: pointer;
  opacity: $opacityTextSecondary; }

.process-time {
  opacity: $opacityTextSecondary;
  font-size: $fontSizeSmall; }

.process-counters {
  display: flex; }

.process-counter {
  transition: height $time $easeMd, border-bottom $time linear;
  border-bottom: 4px solid;
  line-height: 1;
  flex-grow: 1;
  flex-shrink: 1;
  text-align: center;
  height: 24px; }

.process-counter--succeeded {
  border-bottom-color: var(--color-success); }

.process-counter--failed {
  border-bottom-color: var(--color-danger); }

.process-counter--handled {
  border-bottom-color: var(--color-warning); }

.process-counter--start {
  border-bottom-color: var(--color-connected); }

.process-counter--not-start {
  border-bottom-color: var(--color-background-gray500); }

.process-counter-label {
  color: var(--color-text-secondary);
  transition: opacity $time $ease;
  padding: 0 4px;
  font-size: 10px;
  font-weight: 500; }
