.batchInfo-list-item {
  min-height: 80px;
  cursor: pointer;
  padding: 16px 16px 16px 24px;
  border-bottom: 1px solid var(--color-border);
  transition: background-color $easeMd $time;

  &:hover {
    background-color: rgba(var(--color-primary), .08); } }

.batchInfo-list-item-details {
  display: flex;
  margin-bottom: 13px; }

@mixin overflowTooltipStyle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.batchInfo-list-item-details-panel {
  width: 35%;
  font-weight: 500;
  @include overflowTooltipStyle; }

.batchInfo-list-item-details-duration {
  width: 65%;
  text-align: right;
  color: $colorGray600;
  font-size: 13px;
  @include overflowTooltipStyle; }

.batchInfo-list-item-status {
  display: flex;
  align-items: baseline; }

.btn.batchInfo-list-item-stopProcess {
  width: 20px;
  height: 20px;
  margin-right: 12px;

  .btn-content {
    height: 20px;
    line-height: 20px; }

  .btn-icon {
    height: 8px;
    width: 8px;
    height: 20px;
    line-height: 20px; } }
