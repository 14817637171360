@import "../vars";

.devices {
  > .bar {
    margin: 8px 24px 12px 12px;
    flex-wrap: wrap;
    height: auto; }

  .definition-title {
    display: block;
    font-size: $fontSizeMedium;
    margin-bottom: 4px; }

  .definition-detail {
    margin-left: 0; } }

.partitionDevices-sidebar {
  width: 35%;
  flex-shrink: 0;
  min-width: 400px;
  max-width: 600px;
  margin-left: 16px;

  .blocks {
    padding: 12px 0; }

  .block {
    padding: 0 24px;

    &:not(:first-child) {
      padding-top: 24px;
      border-top: 1px solid var(--color-border); } }

  .sidebar-content {
    padding-left: 0;
    padding-right: 0; }

  .configuration-rows:not(:first-child) {
    margin-top: 16px; }

  .form-row {
    padding: 0 24px; }

  .form-row-content {
    padding-left: 40px;
    position: relative; }

  .device {
    box-shadow: none;

    &:not(:first-child) {
      border-top: 1px solid var(--color-border-light); } } }

.devices-sidebar-button {
  margin-right: 8px; }

.partitionDevices-sidebar {
  .scroll {
    margin-top: 16px;
    padding-top: 0;
    border-top: 1px solid var(--color-border); } }

.partitionDevices-sidebar-button {
  margin-left: 12px; }

.devices-sidebar-bypass {
  display: flex;
  align-items: center;
  padding: 0 24px;
  margin: 24px 0 0; }

.devices-content {
  padding-right: 18px;
  overflow-y: scroll; }

.rssi .title .definition-detail {
  font-size: 12px;
  font-weight: normal;
  color: var(--color-text-light);
  text-transform: none !important;
  opacity: 1; }

.rssi-noMeasurements {
  text-align: center;
  margin: 24px 42px 64px;
  color: var(--color-text-secondary);

  .title {
    color: var(--color-text);
    margin-bottom: 8px; } }

.rssi-noMeasurements-content {
  margin: 12px 8px; }

.configuration-outdateNotice {
  text-align: center;
  margin: 12px 42px 42px;
  color: var(--color-text-secondary);

  .title {
    color: var(--color-text);
    margin-bottom: 8px; } }

.configuration-outdateNotice--content {
  margin: 12px 8px; }

.plinkDebug {
  .hint {
    display: block;
    min-width: 100px; }

  > .btn:last-child {
    margin-top: 16px; } }

.blockSubsection + .blockSubsection {
  margin-top: 24px; }
