$navBlindWidth: 324px;

.navBlind {
  width: $navBlindWidth;
  box-shadow: $boxShadowAppAsside;
  background: var(--color-background-card);
  z-index: 4;
  position: absolute;
  height: 100%;
  animation: openNavBlindLarge $time $easeMd;
  transform: translate3d($mainAssideWidthLarge, 0, 0); }

.navBlind-title {
  height: 72px;
  padding: 24px;
  font-size: 17px;
  font-weight: bold;
  border-bottom: 1px solid var(--color-border); }

.btn.navBlind-close {
  position: absolute;
  right: 7px;
  top: 8px;

  .btn-icon {
    color: $colorGray600; } }

.navBlind-list {
  overflow: auto;
  height: calc(100% - 72px); // header height
  padding-bottom: 16px;
  scrollbar-width: thin;
  scrollbar-color: var(--color-primary30) var(--color-background); }

.navBlind-list-moreBtnHolder {
  display: flex;
  justify-content: center;
  padding: 16px 0;

  .btn {
    width: 66.6%; } }


@media screen and (max-width: 1280px) {
  .navBlind {
    animation: openNavBlindMedium $time $easeMd;
    transform: translate3d($mainAssideWidthMedium, 0, 0); } }


@keyframes openNavBlindLarge {
  from {
    transform: translate3d($mainAssideWidthLarge - $navBlindWidth, 0, 0); }
  to {
    transform: translate3d($mainAssideWidthLarge, 0, 0); } }

@keyframes openNavBlindMedium {
  from {
    transform: translate3d($mainAssideWidthMedium - $navBlindWidth, 0, 0); }
  to {
    transform: translate3d($mainAssideWidthMedium, 0, 0); } }
