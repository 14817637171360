@import "../vars";
.eventRules {
  .page-top > .search,
  .page-top > .bar {
    margin-right: 0; } }

.eventRule-table {
  .table-content {
    overflow-x: auto;
    scrollbar-color: var(--color-primary30) var(--color-background); } }

.eventRule-row {
  .btn:hover {
    opacity: .7; }

  .table-cell:nth-child(1),
  .table-cell:nth-child(3),
  .table-cell:nth-child(5),
  .table-cell:nth-child(7) {
    color: var(--color-text-secondary); }

  .table-cell:nth-child(3),
  .table-cell:nth-child(5),
  .table-cell:nth-child(7) {
    margin-left: auto; }

  .table-cell:nth-child(2),
  .table-cell:nth-child(4),
  .table-cell:nth-child(6) {
    padding-right: 32px;
    width: calc(160px + 32px)!important; }

  // Edit button
  .table-cell:nth-last-child(2) {
    margin-left: auto; } }

.eventRules-tabs {
  box-shadow: none;
  padding-left: 16px;
  margin-bottom: 16px; }

.eventRules-tabsContainer {
  display: flex;
  flex-direction: row;
  height: 100%; }

.evenRules-elements-name {
  border: var(--color-primary) 1px solid;
  border-radius: 2px;
  padding: 3px 10px;
  background-color: rgba(var(--color-primary-rgb), 0.05);
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  // hack to apply overflow text tooltip with paddings
  &:after {
    content: '';
    display: inline-block;
    width: 18px;
    height: 15px; } }


.eventRules-master-steps-container {
  display: flex;
  justify-content: space-around;
  padding-top: 16px;
  position: relative; }

.eventRules-master-steps {
  margin: 0 -32px;
  height: 100px;
  background-color: var(--evenRule-master-steps-background);
  position: relative; }

.eventRules-master-steps-line {
  height: 4px;
  width: 168px;
  position: absolute;
  left: 9%;
  top: 34%;
  background-color: var(--evenRule-master-steps-line-color); }

.eventRules-master-steps-line:nth-child(2) {
  width: 109px;
  left: 33.26%; }

.eventRules-master-steps-line:nth-child(3) {
  width: 166px;
  left: 53.16%; }

.eventRules-master-steps-line:nth-child(4) {
  width: 96px;
  left: 72.5%; }

.eventRules-master-steps-arrow {
  position: absolute;
  width: 20px;
  height: 40px;
  display: inline-block;
  top: 16.8%;

  &:before,
  &:after {
    content: "";
    position: absolute;
    background-color: var(--evenRule-master-steps-line-color);
    border-radius: 0.2rem;
    display: block;
    left: -5%;
    height: 5px;
    width: 100%;
    transform: rotate(-45deg); }

  &:before {
    top: 58%; }
  &:after {
    bottom: 58%;
    transform: rotate(45deg); } }

.eventRules-master-steps-arrow__first {
  left: 47.1%; }

.eventRules-master-steps-arrow__second {
  left: 84.7%; }


.eventRules-step-icon {
  display: flex;
  flex-direction: column;
  align-items: center; }

.eventRules-step-icon__zone {
  left: 5.86%; }

.eventRules-step-icon__notification {
  left: 27.29%; }

.eventRules-step-icon__state {
  left: 49.57%; }

.eventRules-step-icon__event {
  left: 70.14%; }

.eventRules-step-icon__save {
  left: 89.29%; }

.eventRules-step-icon-holder {
  display: flex;
  width: 40px;
  height: 40px;
  background-color: var(--eventRule-master-steps-icon-holder-background);
  border-radius: 50%;
  transition: background-color $time;
  color: var(--eventRule-master-steps-icon-color);

  > svg {
    margin: auto;
    height: 26px;
    width: 26px; } }

.eventRules-step-icon-title {
  color: var(--eventRule-master-steps-icon-title-color);
  font-weight: 500;
  margin-top: 12px;
  display: inline-block; }

.eventRules-master-steps__zone {
  .eventRules-step-icon__zone {
    .eventRules-step-icon-holder {
      background-color: var(--color-primary);
      color: white; }

    .eventRules-step-icon-title {
      color: var(--eventRule-master-steps-icon-title-active-color); } }

  .eventRules-master-steps-line:nth-child(1) {
      background-color: var(--color-primary); } }

.eventRules-master-steps__notification {
  .eventRules-step-icon__zone, .eventRules-step-icon__notification {
    .eventRules-step-icon-holder {
      background-color: var(--color-primary);
      color: white; } }

  .eventRules-step-icon__notification {
    .eventRules-step-icon-title {
      color: var(--eventRule-master-steps-icon-title-active-color); } }


  .eventRules-master-steps-line:nth-child(1) {
      background-color: var(--color-primary); } }

.eventRules-master-steps__state {
  .eventRules-step-icon__zone,
  .eventRules-step-icon__notification,
  .eventRules-step-icon__state, {
    .eventRules-step-icon-holder {
      background-color: var(--color-primary);
      color: white; } }

  .eventRules-step-icon__state {
    .eventRules-step-icon-title {
      color: var(--eventRule-master-steps-icon-title-active-color); } }

  .eventRules-master-steps-line:nth-child(1),
  .eventRules-master-steps-line:nth-child(2),
  .eventRules-master-steps-arrow__first:after,
  .eventRules-master-steps-arrow__first:before {
    background-color: var(--color-primary); } }

.eventRules-master-steps__event {
  .eventRules-step-icon__zone,
  .eventRules-step-icon__notification,
  .eventRules-step-icon__state,
  .eventRules-step-icon__event, {
    .eventRules-step-icon-holder {
      background-color: var(--color-primary);
      color: white; } }

  .eventRules-step-icon__event {
    .eventRules-step-icon-title {
      color: var(--eventRule-master-steps-icon-title-active-color); } }

  .eventRules-master-steps-line:nth-child(1),
  .eventRules-master-steps-line:nth-child(2),
  .eventRules-master-steps-arrow__first:after,
  .eventRules-master-steps-arrow__first:before,
  .eventRules-master-steps-line:nth-child(3) {
    background-color: var(--color-primary); } }

.eventRules-master-steps__save {
  .eventRules-step-icon__zone,
  .eventRules-step-icon__notification,
  .eventRules-step-icon__state,
  .eventRules-step-icon__event,
  .eventRules-step-icon__save, {
    .eventRules-step-icon-holder {
      background-color: var(--color-primary);
      color: white; } }

  .eventRules-step-icon__save {
    .eventRules-step-icon-title {
      color: var(--eventRule-master-steps-icon-title-active-color); } }

  .eventRules-master-steps-line:nth-child(1),
  .eventRules-master-steps-line:nth-child(2),
  .eventRules-master-steps-arrow__first:after,
  .eventRules-master-steps-arrow__first:before,
  .eventRules-master-steps-line:nth-child(3),
  .eventRules-master-steps-line:nth-child(4),
  .eventRules-master-steps-arrow__second:after,
  .eventRules-master-steps-arrow__second:before {
    background-color: var(--color-primary); } }

.eventRules-master-steps-cardActions {
  display: flex;

  .btn {
    &:hover:before {
      opacity: .1; } } }

.eventRules-master-steps-prevButton {
  margin-right: auto;
  background-color: var(--eventRule-master-steps-prevButton-background);

  .btn-content {
    display: flex;
    align-content: space-evenly;
    justify-content: space-around;
    align-items: center;

    .btn-icon {
      transform: rotate(180deg);
      margin-right: 0; } } }

.eventRules-master-steps-nextButton {
  background-color: var(--eventRule-master-steps-nextButton-background);
  margin-left: auto;
  color: var(--eventRule-master-steps-nextButton-color);

  .btn-content {
    display: flex;
    flex-direction: row-reverse;
    align-content: space-evenly;
    justify-content: space-around;
    align-items: center;

    .btn-icon {
      margin-right: 0; } } }

.eventRules-master-steps-saveButton {
    margin-left: auto;
    background-color: var(--eventRule-master-steps-nextButton-background); }

.eventRules-master-content-disclaimer {
  margin-top: 24px; }

.eventRules-master-content-addButton {
  margin-right: -8px;
  color: var(--color-primary);
  font-size: 12px; }

.eventRules-master-content-bar {
  padding-top: 6px;
  min-height: 92px;
  align-items: flex-start;

  .form-field--checkbox {
    margin-right: auto;
    height: 36px;
    display: flex;
    align-items: center; } }

.eventRules-master-content__save {
  padding-top: 24px;
  padding-bottom: 45px;

  .form-field--checkbox {
    margin-top: 18px;
    height: 45px;
    display: flex;
    align-items: flex-end; }

  .col:nth-child(2) .form-field--checkbox {
    margin-top: calc(18px + 45px + 18px); } }


@media screen and (max-width: 1350px) {
  .eventRule-row {
    .table-cell:nth-child(2),
    .table-cell:nth-child(4),
    .table-cell:nth-child(6) {
      padding-right: initial; } } }
