.recentHistoryWidget {
  border-bottom: 1px solid var(--color-border-light);
  padding-bottom: 22px;
  margin-bottom: 8px; }

.recentHistoryWidget-dropDown {
  position: absolute;
  top: 4px;
  right: 12px;
  width: 12px;
  opacity: .8;
  height: 28px;
  line-height: 28px;
  display: inline-block;
  transform: rotate(-90deg);
  color: var(--color-text); }

.recentHistoryWidget-lightTitle {
  font-size: 12px;
  color: var(--color-text-light);
  padding-left: 36px;
  margin-top: 4px;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 500; }

.recentHistoryWidget-item {
  display: block;
  font-size: 14px;
  line-height: 16px;
  height: 16px;
  position: relative; }

.recentHistoryWidget-item + .recentHistoryWidget-item {
  margin-top: 12px; }

.recentHistoryWidget-item--active {
  color: var(--color-primary); }
