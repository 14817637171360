@import "~ipmp-react-ui/themes/themes";
@import "~ipmp-react-ui/themes/sanMarino";
@import "~ipmp-react-ui/themes/valencia";

.theme--dark {
  .app-aside-logo {
    color: white; }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(white, .2); }

  .login {
    background: #00213F linear-gradient(to bottom, #00213F, #0B163F, #10113F); }

  .about .card-header {
    background: #00539E linear-gradient(45deg, #10113F, #00213F); }

  .device-image {
    opacity: .7; }

  .device--disabled .device-image {
    opacity: .4; } }


.theme--valencia {
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px lighten(#2E2C2B, 4) inset !important;
    -webkit-text-fill-color: white !important; }

  --evenRule-master-steps-background: rgba(196, 196, 196, 0.05);
  --eventRule-master-steps-icon-holder-background: #{$colorGray800};
  --eventRule-master-steps-prevButton-background: #3D3C3C;
  --eventRule-master-steps-nextButton-background: black;
  --eventRule-master-steps-nextButton-color: white;
  --eventRule-master-steps-icon-title-color: #{$colorGray600};
  --eventRule-master-steps-icon-title-active-color: white;
  --evenRule-master-steps-line-color: rgb(59, 59, 59);
  --eventRule-master-steps-icon-color: #919090;
  --color-primary-rgb: 222, 126, 3; }


.theme--sanMarino {
  --evenRule-master-steps-background: #{$colorGray100};
  --eventRule-master-steps-icon-holder-background: #{$colorGray300};
  --eventRule-master-steps-prevButton-background: #{$colorGray200};
  --eventRule-master-steps-nextButton-background: var(--color-primary);
  --eventRule-master-steps-nextButton-color: white;
  --eventRule-master-steps-icon-title-color: #{rgba($colorGray900, 0.5)};
  --eventRule-master-steps-icon-title-active-color: #{$colorGray900};
  --evenRule-master-steps-line-color: rgb(231, 231, 231);
  --eventRule-master-steps-icon-color: rgba(0,0,0, 0.3);
  --color-primary-rgb: 45, 114, 185; }

