@mixin installerCardSettings {
  .card-header {
    text-transform: none; } }

.installerBlind {
  @include installerCardSettings;

  .spinner-container {
    margin: 100px; } }

.installerBlind-table {
  margin: 64px -32px 40px;

  .table-header {
    border-bottom: 1px solid var(--color-border-table); }

  .table-header-cell {
    padding-bottom: 14px; }

  .table-content {
    box-shadow: none; }

  .table-cell:first-child .table-cell-content,
  .table-header-cell:first-child {
    padding-left: 32px; }

  .table-row:last-child {
    border-bottom: 1px solid var(--color-border-table); }

  .table-cell:last-child {
    .table-cell-content {
      padding-left: 10px; } } }

.installerBlind-addPanel {
  margin: auto;
  display: block; }

.installerAddPanel {
  @include installerCardSettings;

  .search-bar {
    background-color: var(--color-background-gray200);
    box-shadow: none;

    ::placeholder {
      color: var(--color-text-light); } }

  .pager {
    position: relative;
    right: -17px;

    .btn-content {
      font-weight: normal; }

    > .btns {
      box-shadow: none;

      .btn {
        background-color: var(--color-background-gray200); } } } }

.installerBlind {
  .bar {
    height: auto;
    align-items: flex-start; } }

.installerBlind-info {
  display: flex;
  flex-direction: column;
  margin-top: 20px; }

.installerBlind-info-section {
  word-break: break-all; }

.installerBlind-info-section + .installerBlind-info-section {
  margin-top: 12px; }

.installerBlind-info-icon {
  width: 17px;
  margin-right: 15px;
  vertical-align: text-top; }

.installerBlind-info-emailTitle {
  color: var(--color-text-light);
  vertical-align: 4px;
  font: inherit;
  font-family: "Roboto", serif; }

.reset-password-button {
  margin-top: 20px; }
