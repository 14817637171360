//---- OPACITY ----
$opacityTextSecondary: .62;
$opacityTextSuperLight: .2;
$opacityBlackout: .62;

//---- COLORS ----
$colorGray50: #FAFAFA;
$colorGray100: #F5F5F5;
$colorGray200: #EEEEEE;
$colorGray300: #E0E0E0;
$colorGray400: #BDBDBD;
$colorGray500: #9E9E9E;
$colorGray600: #757575;
$colorGray700: #616161;
$colorGray800: #424242;
$colorGray900: #212121;

$colorJCBlue: #00539E;
$colorJCCyan: #00BBE3;
$colorJCGreen: #8CC63E;
$colorJCOrange: #A9650F;

$colorBackgroundOrange: rgba(169, 101, 15, 0.05);

//---- Font SIZE ----
$fontSizeSuperBig: 28px;
$fontSizeBig: 22px;
$fontSizeLarge: 18px;
$fontSizeMedium: 15px;
$fontSizePrimary: 14px;
$fontSizeSmall: 12px;
$fontSizeSuperSmall: 9px;

//---- SHADOWS ----
$boxShadowElevation05: 0 3px 1px -1px rgba(black,0.08), 0 1px 3px -1px rgba(black,0.1);
$boxShadowElevation1: 0 3px 1px -2px rgba(black, .08), 0 2px 2px 0 rgba(black, .05), 0 1px 5px 0 rgba(black, .1);
$boxShadowElevation15: 0 3px 4px -2px rgba(black, .1), 0 2px 3px 0 rgba(black, .08), 0 1px 5px 0 rgba(black, .1);
$boxShadowElevation2: 0 5px 5px -3px rgba(black, .2), 0 8px 10px 1px rgba(black, .14), 0 3px 14px 2px rgba(black, .12);
$boxShadowElevation3: rgba(black, 0.137255) 0px 8px 10px 1px, rgba(black, 0.117647) 0px 3px 14px 2px, rgba(black, 0.2) 0px 5px 5px -3px;
$boxShadowElevation4: 0 7px 8px -4px rgba(black,.2), 0 13px 19px 2px rgba(black,.14), 0 5px 24px 4px rgba(black,.12);
$boxShadowAppAsside: 3px 0 1px -2px rgba(black, .08), 2px 0 2px 0 rgba(black, .05), 1px 0 5px 0 rgba(black, .1);

//---- TIMING ----
$time: .25s;

//---- EASING ----
$easeShadow: cubic-bezier(0.4, 0, 0.2, 1);
$easeMd: cubic-bezier(.4,0,.2,1);

//---- EASINGS ----
$linear: cubic-bezier(0.250, 0.250, 0.750, 0.750);
$ease: cubic-bezier(0.250, 0.100, 0.250, 1.000);
$ease-in: cubic-bezier(0.420, 0.000, 1.000, 1.000);
$ease-out: cubic-bezier(0.000, 0.000, 0.580, 1.000);
$ease-in-out: cubic-bezier(0.420, 0.000, 0.580, 1.000);

$easeInQuad: cubic-bezier(0.550, 0.085, 0.680, 0.530);
$easeInCubic: cubic-bezier(0.550, 0.055, 0.675, 0.190);
$easeInQuart: cubic-bezier(0.895, 0.030, 0.685, 0.220);
$easeInQuint: cubic-bezier(0.755, 0.050, 0.855, 0.060);
$easeInSine: cubic-bezier(0.470, 0.000, 0.745, 0.715);
$easeInExpo: cubic-bezier(0.950, 0.050, 0.795, 0.035);
$easeInCirc: cubic-bezier(0.600, 0.040, 0.980, 0.335);
$easeInBack: cubic-bezier(0.600, -0.280, 0.735, 0.045);

$easeOutQuad: cubic-bezier(0.250, 0.460, 0.450, 0.940);
$easeOutCubic: cubic-bezier(0.215, 0.610, 0.355, 1.000);
$easeOutQuart: cubic-bezier(0.165, 0.840, 0.440, 1.000);
$easeOutQuint: cubic-bezier(0.230, 1.000, 0.320, 1.000);
$easeOutSine: cubic-bezier(0.390, 0.575, 0.565, 1.000);
$easeOutExpo: cubic-bezier(0.190, 1.000, 0.220, 1.000);
$easeOutCirc: cubic-bezier(0.075, 0.820, 0.165, 1.000);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.320, 1.275);

$easeInOutQuad: cubic-bezier(0.455, 0.030, 0.515, 0.955);
$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1.000);
$easeInOutQuart: cubic-bezier(0.770, 0.000, 0.175, 1.000);
$easeInOutQuint: cubic-bezier(0.860, 0.000, 0.070, 1.000);
$easeInOutSine: cubic-bezier(0.445, 0.050, 0.550, 0.950);
$easeInOutExpo: cubic-bezier(1.000, 0.000, 0.000, 1.000);
$easeInOutCirc: cubic-bezier(0.785, 0.135, 0.150, 0.860);
$easeInOutBack: cubic-bezier(0.680, -0.550, 0.265, 1.550);

// ----DIMENSIONS----
$mainAssideWidthLarge: 250px;
$mainAssideWidthMedium: 190px;
$tableRowHeight: 45px;
$barHeight: 40px;

//---- RESPONSIVE ----
$xsmall-1: 360px;
$xsmall-2: 400px;
$xsmall-3: 480px;
$small-1: 600px;
$small-2: 720px;
$small-3: 840px;
$small-4: 960px;
$medium-1: 1024px;
$medium-2: 1280px;
$large-1: 1440px;
$large-2: 1600px;
$xlarge: 1920px;

$min-xsmall-1: $xsmall-1;
$min-xsmall-2: $xsmall-2;
$min-xsmall-3: $xsmall-3;
$min-small-1: $small-1;
$min-small-2: $small-2;
$min-small-3: $small-3;
$min-small-4: $small-4;
$min-medium-1: $medium-1;
$min-medium-2: $medium-2;
$min-large-1: $large-1;
$min-large-2: $large-2;
$min-xlarge: $xlarge;

$max-xsmall-1: $min-xsmall-2 - 1px;
$max-xsmall-2: $min-xsmall-3 - 1px;
$max-small-1: $min-small-2 - 1px;
$max-small-2: $min-small-3 - 1px;
$max-small-3: $min-small-4 - 1px;
$max-medium-1: $min-medium-2 - 1px;
$max-large-1: $min-large-2 - 1px;
