.blindModalDefinition {
  display: grid;
  grid-template-columns: repeat(auto-fill, 176px); }

.blindModalDefinition-item-title {
  color: var(--color-text-secondary);
  font-weight: 500;
  text-transform: uppercase; }

.blindModalDefinition-item-definition {
  margin-top: 13px; }
