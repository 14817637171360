@import "../vars";

.device {
  display: flex;
  align-items: flex-start;
  position: relative;
  background-color: var(--color-background-card);
  box-shadow: $boxShadowElevation05;
  border-radius: 2px; }

.device--disabled {
  .device-image-src {
    opacity: .4; } }

.device--changed {
  background-color: var(--color-enabled);

  .device-image {
    background-color: var(--color-enabled); } }

.device--active {
  background-color: var(--color-background-primary);
  color: var(--color-text-white);
  --color-text: white;
  --color-border-light: #{rgba(white, .2)};
  --color-background-black5: #{rgba(white, .1)};
  --color-text-secondary: #{rgba(white, .7)};
  --color-text-light: #{rgba(white, .5)};
  --color-text-super-light: #{rgba(white, .4)};

  .device-zone {
    color: white;
    background-color: var(--color-background-card20); }

  .device-image {
    box-shadow: $boxShadowElevation1; }

  .worry {
    color: var(--color-text-light); }

  .worry--alarm {
    color: var(--color-text-white); }

  .worry--alert {
    color: var(--color-text-white); } }

.device-openMark {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 8px;
  height: 8px;
  z-index: 3;
  border-radius: 50%;
  background-color: var(--color-danger); }

.device-icons {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  margin-left: 8px; }

.device-icon-holder:not(:first-child) {
  margin-left: 8px; }

.device-icon {
  height: 18px;
  width: 18px; }

.device-image {
  width: 80px;
  height: 80px;
  line-height: 80px;
  margin: 8px 0 8px 8px;
  padding: 8px;
  border-radius: 2px;
  flex-shrink: 0;
  align-self: center;
  position: relative;
  background-color: var(--color-background-card); }

.device-image-src {
  width: 100%;
  height: 100%;
  display: block;
  background: center no-repeat;
  background-size: contain; }

.device-image-overlay {
  position: absolute;
  left: 50%;
  width: 100%;
  text-align: center;
  top: 50%;
  font-size: $fontSizeSuperSmall;
  text-shadow: 0 1px white, 0 0 2px white;
  font-weight: 500;
  color: var(--color-text);
  transform: translate(-50%, -50%);
  text-transform: uppercase; }

.device-title {
  font-size: $fontSizeMedium;
  font-weight: 500;
  margin-bottom: 6px;
  line-height: 20px; }

.device-troubles {
  line-height: 22px;
  text-transform: uppercase; }

.device-troubles--preenroll {
  font-size: $fontSizeSmall;
  color: var(--color-warning);
  font-weight: 500; }

.device-troubles--enrolling {
  font-size: $fontSizeSmall;
  color: var(--color-warning); }

.device-troubles--empty {
  font-size: $fontSizeSmall;
  color: var(--color-text-super-light); }

.device-port {
  display: block;
  position: absolute;
  bottom: 16px;
  right: 12px;
  font-size: $fontSizeSmall;
  line-height: 18px;
  opacity: $opacityTextSecondary;

  + .device-troubles {
    padding-right: 64px; } }

.device-content {
  padding: 14px 16px;
  flex-grow: 1;
  max-width: calc(100% - 88px); }

.device-hint {
  margin-bottom: 10px;
  height: 12px;
  line-height: 12px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  color: var(--color-text-secondary);
  width: 100%; }

.device-hint-content {
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 1;

  + .device-hint-content {
    padding-left: 8px;
    margin-left: 8px;
    border-left: 1px solid var(--color-text-super-light); } }

.device-zone {
  display: inline-block;
  border-radius: 2px;
  font-weight: 500;
  padding: 2px 3px;
  background-color: var(--color-primary);
  color: white;
  margin-right: 8px;
  text-align: center;
  vertical-align: baseline; }

.device-modules {
  align-self: center;
  padding-right: 16px; }

.device-module {
  text-align: left;
  font-weight: 500;
  white-space: nowrap;
  cursor: default;

  .communication {
    margin-right: 8px; }

  .device-icon-holder {
    display: inline-block; }

  &:not(:first-child) {
    margin-top: 16px; } }

.device-module--notInstalled {
  color: var(--color-disabled); }

.device--active .device-module--notInstalled {
  color: var(--color-disabled);

  .communication--none {
    color: inherit; } }

.partitions {
  font-size: 11px;
  color: var(--color-text-secondary);
  margin-left: 4px;
  line-height: 13px;
  height: 13px;
  border-radius: 2px;
  background-color: var(--color-background-black5);
  padding: 0 1px; }

.partition {
  padding: 0 3px; }

.partition + .partition {
  border-left: 1px solid var(--color-border-light); }

.device-walktestMark {
  align-self: center;
  margin-right: 16px;
  background-color: var(--color-border-light);

  &.success {
    color: white;
    background-color: var(--color-success); }

  &.danger {
    color: white;
    background-color: var(--color-danger); } }

.device-walktestMark-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }

.device-shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 0;
  left: 0;
  background: var(--color-background-card50); }

.blindModalDevices {
  .card-content {
    padding-top: 72px;
    padding-bottom: 0;
    height: 100%;
    display: flex;
    flex-flow: column; }

  .video {
    margin: -16px -32px 0; }

  .video-definition {
    padding-left: 32px;
    padding-right: 32px; }

  .video-title {
    padding-left: 33px;
    padding-right: 32px; }

  .video-actions {
    border-top: none;
    padding-left: 24px;
    padding-right: 24px; }

  .configuration-title {
    margin-left: 0;
    margin-right: 0; }

  .tabs {
    margin: 0 -32px;
    padding: 0 32px; }

  .hr {
    margin-left: -32px;
    margin-right: -32px; }

  .devicesList {
    margin-top: 16px;
    width: 100%; }

  .device {
    box-shadow: none;
    background-color: var(--color-background-gray200);
    margin-bottom: 12px; }

  //.device-image
  //margin-left: -8px

  .block {
    margin-left: -32px;
    margin-right: -32px;
    padding: 0 32px;

    > .title {
      text-transform: uppercase; } }

  .block:not(:first-child) {
    padding-top: 24px;
    border-top: 1px solid var(--color-border); }

  .processLoader {
    margin: 42px; }

  .card-close {
    top: 16px; }

  .sidebar-content {
    margin-left: -32px;
    margin-right: -32px;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 32px;

    .blocks {
      width: 100%; } }

  .device-configuration {
    .form-row {
      margin: 0 -32px;
      padding: 0 32px; }

    .form-row-content {
      padding-left: 40px;
      position: relative; } }

  .list {
    margin-top: 16px; } }

.blindModalDevices-firmware {
  .form-field {
    flex-grow: 1;
    margin-right: 16px; } }

.blindModalDevices-actions {
  &:not(:first-child) {
    margin-top: 24px; }

  .processLoader {
    margin-top: 24px; } }

.blindModalDevices-paginator {
  position: absolute;
  top: 14px; }

[dir="rtl"] .blindModalDevices-paginator-prev {
  .btn-icon {
    transform: rotate(180deg); } }

[dir="rtl"] .blindModalDevices-paginator-next {
  .btn-icon {
    transform: rotate(180deg); } }

.blindModalDevices-device {
  box-shadow: none; }

.diagnostic-index {
  font-size: $fontSizeSmall;
  color: var(--color-text-super-light);
  margin-right: 8px; }

.device-icon-sub {
  background: var(--color-background-card);
  color: var(--color-text);
  position: absolute;
  bottom: -8px;
  font-size: $fontSizeSuperSmall;
  left: 13px;
  padding: 1px;
  border-radius: 2px; }


.blindModalDevices-device {
  .device-icon-sub {
    background: var(--color-background-gray200); } }
