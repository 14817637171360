.recentHistory-item--active {
  .recentHistory-item-header-dropDownIcon {
    transform: rotate(0deg); }

  .recentHistory-item-panelList {
    max-height: 197px; } }

.recentHistory-item-header {
  display: flex;
  position: relative;
  padding-left: 50px;
  height: 72px;
  cursor: pointer;
  transition: background-color $time $easeOutSine;


  &:hover {
    background-color: var(--color-background-gray100); } }

.recentHistory-item-header-dropDownIcon {
  position: absolute;
  transform: rotate(-90deg);
  left: 15px;
  top: 50%;
  transition: transform $time / 2 $easeOutCubic; }

.recentHistory-item-infoWrapper {
  padding: 15px 15px 15px 0; }

.recentHistory-item-info-iconWrapper {
  width: 26px;
  display: inline-block; }

.recentHistory-item-info + .recentHistory-item-info {
  margin-top: 10px; }

.recentHistory-item-info--panelSerial {
  font-size: 12px;
  font-weight: 500;
  color: var(--color-primary); }

.recentHistory-item-info--userEmail {
  font-size: 15px;
  font-weight: 500; }

.recentHistory-item-panelList {
  overflow: auto;
  background-color: var(--color-background);
  max-height: 0;
  transition: max-height $ease-in-out $time;
  scrollbar-color: var(--color-primary30) var(--color-background-black10);

  .spinner-container {
    margin: 24px auto; } }

.recentHistory-item-panelList-item, .recentHistory-item-panelList-title {
  padding: 0 48px; }

.recentHistory-item-panelList-title {
  margin-bottom: 6px;
  margin-top: 32px;
  font-size: 12px;
  font-weight: 500;
  color: var(--color-text-light);
  text-transform: uppercase; }


.recentHistory-item-panelList-item {
  height: 44px;
  border-bottom: 1px solid var(--color-border-light);
  display: flex;
  align-items: center;

  &:last-child {
    margin-bottom: 22px; } }
